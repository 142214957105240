.resources-page {
    align-items: center;
    align-content: center;
    background-color: #F4F7FA;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: inset;
    display: flex;
    flex-direction: column;
    font-family: 'GreycliffCFBold', sans-serif;
    height: 90vh;
    overflow-y: auto;
    width: 100%;

    .not-found-number {
        font-size: 500%;
        padding: 0.5em;
      }
      
      .not-found-text {
        font-size: 200%;
        padding: 1em;
      }
      
      * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
      }
      
      body {
        font-family: Arial, sans-serif;
        background-color: #f4f4f4;
      }
      
      .container {
        width: 100%;
        margin: 0 auto;
        padding: 20px;
      }
      
      .row {
        margin-bottom: 40px;
      }
    
      h1 {
        color: #2A2E33;
        font-size: 32px;
        font-weight: 700;
        line-height: 38px;
        margin-bottom: 20px;
      }
      
      h2 {
        color: #2A2E33;
        font-size: 24px;
        font-weight: 700;
        line-height: 26px;
        margin-bottom: 20px;
      }
      
      .cards-container {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
      }
      
      .card {
        background-color: white;
        border-radius: 8px;
        width: calc(25% - 20px);
        height: 120px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        transition: transform 0.2s ease;
        padding: 10px;
        display: flex;
        flex-direction: column;
        min-width: 200px;
      }
      
      .card:hover {
        transform: translateY(-5px);
      }
      
      .card-icon {
        font-size: 40px;
        margin-bottom: 10px;
      }
      
      .card-text {
        color: #0E2746;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
      }
      
      @media (max-width: 800px) {
        .card {
            height: 65px;
          width: 100%; 
          flex-direction: row;
          padding: 15px 10px;
        }
      
        .card-icon {
          font-size: 30px;
          margin-right: 15px;
        }
      
        .card-text {
          font-size: 18px;
          padding-top: 4px;
          text-align: left;
        }
      }
}
  