/* dashboard css */
.my-dashboard {
  background-color: #F4F7FA;
  display: flex;
  font-family: 'GreycliffCFMedium', Arial, sans-serif;
  justify-content: start;
  width: 100%;
  gap: 20px; /* Add some space between the columns */
  flex-wrap: wrap; /* Allow columns to wrap on smaller screens */
  padding-top: 2rem;
  padding-left: 2rem;
  
  .card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s ease;
    padding: 10px;
    display: flex;
    flex-direction: column;
    min-width: 200px;
    max-height: fit-content;
  }

  .profile-th {
    font-size: medium;
    color: #2A2E33;
    font-weight: 500;
    line-height: 20px;
  }

  .profile-td {
    display: flex;
    justify-content: space-between;
  }

  .profile-bold-th {
    font-size: large;
    color: #2A2E33;
    font-weight: 700;
    line-height: 20px;
  }

  .icon-title-container {
    align-items: start;
    display: flex;
    flex-direction: column;
    height: 5vh;
    margin-left: 1rem;
    margin-bottom: 0.5rem;
  }
}

.my-dashboard-col {
  display: flex;
  flex-direction: column;
  width: 45%;
}


.my-dashboard-col .card-body {
  padding: 0;
}

/* Mobile breakpoints */
@media (max-width: 800px) {
  .my-dashboard-col {
    width: 100%;
    margin-bottom: 20px;
  }
}

.my-membership-table{
  width:100%;
  padding:0.5em;
  text-align:center;
}

.card-body{
  padding:0.5em;
  background-color: #fff;
}

.dashboard-input{
  width:100%;
  background-color:transparent;
  margin: 2px 0;
  border: none;
  border-bottom: 1px solid #EDECE0;
  outline:0;
  word-wrap: break-word;
  word-break: break-all;
  color:black;
  font-family: 'GreycliffCFMedium', Arial, sans-serif;
  font-size:16px;
  appearance: none;
  -webkit-appearance: none; /* For Safari */
  -moz-appearance: none;
}

select .dashboard-input {
  font-size: x-large;
}

table tr:last-child input.dashboard-input,
table tr:last-child select.dashboard-input {
  border-bottom: none;
}

.edit-button-container {
  border-top: 1px solid #EDECE0;
  display: flex;
  justify-content: end;
}

.button-cta-v2 {
  width: 90%;
  margin: 1em;               
  padding: 10px;                
  background-color: white;     
  border: 1px solid #00AAAF;
  box-sizing: border-box; 
  border-radius: 4px;          
  color: black;              
  font-size: 16px;             
  text-align: center;          
  cursor: pointer;            
  transition: background-color 0.3s ease, border-color 0.3s ease;  
}

.button-cta-v2:hover {
  background-color: #00AAAF;
  color: white;
  border-color: #00AAAF;
}

.button-v2-secondary {
  width: 100px;
  margin: 1em;               
  padding: 10px;                
  background-color: white;     
  border: 1px solid #00AAAF;
  box-sizing: border-box; 
  border-radius: 4px;          
  color: black;              
  font-size: 16px;             
  text-align: center;          
  cursor: pointer;            
  transition: background-color 0.3s ease, border-color 0.3s ease;  
}

.button-v2-primary {
  width: 100px;
  margin: 1em;               
  padding: 10px;                
  background-color: #285D6A;     
  border: 1px solid #00AAAF;
  box-sizing: border-box; 
  border-radius: 4px;          
  color: white;              
  font-size: 16px;             
  text-align: center;          
  cursor: pointer;            
  transition: background-color 0.3s ease, border-color 0.3s ease;  
}

.link-style-button {
  background: none;
  border: none;
  color: #285D6A;
  font-size: 16px;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.3s ease;
}

.link-style-button:hover {
  color: #007b8a;
}

.title-edit-container {
  display: flex;
  justify-content: space-between;

  p {
    margin-left: 1em;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

/* loader css */
.lds-grid {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-grid div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fff;
  animation: lds-grid 1.2s linear infinite;
}
.lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}
.lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}
@keyframes lds-grid {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}